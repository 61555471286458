import React from "react";
import { Spinner } from "./Spinner";
import { Link } from "gatsby";

const sizeClassnames = {
  big: "active:scale-[.97] transition px-4 py-3 md:py-3 md:px-6 text-sm rounded-full font-medium text-sm ",
  small: "active:scale-[.97] transition px-2 py-2 text-xs rounded-xl font-medium md:text-sm",
};

const colorClassnames = {
  primary:
    "text-white bg-blue-600 border-2 border-blue-600 active:bg-gray-900 hover:border-gray-900 hover:bg-blue-700 disabled:text-brand-600 disabled:bg-gray-700",
  secondary:
    "text-brand-500 bg-white border-2 border-white  hover:border-brand-100 hover:bg-brand-100 disabled:text-brand-600 disabled:bg-brand-700 focus:ring-4 focus:ring-brand-300",
  red: "text-white bg-red-500 hover:bg-red-600 disabled:text-red-600 disabled:bg-red-600 focus:ring-4 focus:ring-red-300",
  accent:
    "text-white bg-accent-500 hover:bg-accent-600 disabled:text-accent-600 disabled:bg-accent-600 focus:ring-4 focus:ring-accent-300",
  link: "text-brand-500  hover:bg-gray-50 disabled:text-brand-600 disabled:bg-brand-700 focus:ring-4 focus:ring-brand-300",
  primarySecondary:
    "text-brand-500 hover:text-brand-700  border-2 border-brand-500 hover:border-brand-700 disabled:text-brand-600 disabled:bg-brand-700 focus:ring-4 focus:ring-brand-300",
  lightSecondary:
    "text-white  hover:text-white  border-2 border-gray-100 hover:border-brand-300 disabled:text-brand-600 disabled:bg-brand-700 focus:ring-4 focus:ring-brand-300",
};

const Button = ({
  children,
  size = "big",
  color = "primary",
  disabled,
  loading,
  icon,
  className = "",
  transition = true,
  href,
  to,
  ...props
}) => {
  if (href) {
    return (
      <a
        href={href}
        className={`font-display  flex outline-none ${sizeClassnames[size]} ${
          transition ? `transition duration-200 ease-in-out` : ``
        } ${colorClassnames[color]} items-center justify-center ${className}`}
        {...props}
      >
        {" "}
        <span className={loading ? "opacity-0" : `flex items-center`}>
          {icon ? <span className={`mr-1 items-center w-5 h-5`}>{icon}</span> : null}
          {children}
        </span>
      </a>
    );
  } else if (to) {
    return (
      <Link
        to={to}
        className={`font-display  inline-flex outline-none ${sizeClassnames[size]} ${
          transition ? `transition duration-200 ease-in-out` : ``
        } ${colorClassnames[color]} items-center justify-center ${className}`}
        {...props}
      >
        {" "}
        <span className={loading ? "opacity-0" : `flex items-center`}>
          {icon ? <span className={`mr-1 items-center w-5 h-5`}>{icon}</span> : null}
          {children}
        </span>
      </Link>
    );
  }

  return (
    <button
      className={` font-display  flex outline-none ${sizeClassnames[size]} ${
        transition ? `transition duration-200 ease-in-out` : ``
      } ${colorClassnames[color]} items-center justify-center ${className}`}
      {...props}
    >
      <span className={loading ? "opacity-0" : `flex items-center`}>
        {icon ? <span className={`mr-1 items-center w-5 h-5`}>{icon}</span> : null}
        {children}
      </span>

      {loading ? (
        <span className={`absolute`}>
          <Spinner size={size === "small" ? "2" : "4"} />
        </span>
      ) : null}
    </button>
  );
};

export default Button;
