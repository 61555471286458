import React from "react";
import styled from "styled-components";

const Logo = ({ classes }) => (


 <svg viewBox="0 0 232 51"  xmlns="http://www.w3.org/2000/svg" className={`${classes ? classes : "text-brand-500"}`}>
<path d="M96.7374 6.38462L90.4836 25.6154H84.643L80.9514 13.9121L77.0944 25.6154H71.2538L65 6.38462H70.6202L74.5047 18.6648L78.5821 6.38462H83.5961L87.4807 18.7747L91.5305 6.38462H96.7374Z" fill="#0B0B0B" className="letter-main"></path>
<path className="letter-main" d="M112.604 18.1978C112.604 18.2344 112.576 18.6648 112.521 19.489H101.666C101.887 20.2582 102.309 20.8535 102.934 21.2747C103.576 21.6777 104.375 21.8791 105.33 21.8791C106.047 21.8791 106.662 21.7784 107.176 21.5769C107.709 21.3755 108.241 21.0458 108.774 20.5879L111.529 23.4451C110.078 25.0568 107.957 25.8626 105.165 25.8626C103.42 25.8626 101.887 25.533 100.564 24.8736C99.2419 24.2143 98.2133 23.2985 97.4787 22.1264C96.7624 20.9542 96.4042 19.6264 96.4042 18.1429C96.4042 16.6777 96.7532 15.3681 97.4511 14.2143C98.1674 13.0421 99.15 12.1264 100.399 11.467C101.648 10.8077 103.053 10.478 104.614 10.478C106.102 10.478 107.452 10.7894 108.664 11.4121C109.876 12.0165 110.831 12.9048 111.529 14.0769C112.245 15.2308 112.604 16.6044 112.604 18.1978ZM104.642 14.1868C103.833 14.1868 103.154 14.4158 102.603 14.8736C102.07 15.3315 101.731 15.9542 101.584 16.7418H107.7C107.553 15.9542 107.204 15.3315 106.653 14.8736C106.12 14.4158 105.45 14.1868 104.642 14.1868Z" fill="#0B0B0B"></path>
<path className="letter-main" d="M120.444 25.8626C119.195 25.8626 117.965 25.7253 116.753 25.4505C115.54 25.1575 114.567 24.7821 113.832 24.3242L115.403 20.7527C116.082 21.174 116.89 21.5128 117.827 21.7692C118.764 22.0073 119.691 22.1264 120.61 22.1264C121.454 22.1264 122.051 22.044 122.4 21.8791C122.768 21.696 122.951 21.4396 122.951 21.1099C122.951 20.7802 122.731 20.5513 122.29 20.4231C121.868 20.2766 121.188 20.1392 120.251 20.011C119.058 19.8645 118.038 19.6722 117.193 19.4341C116.367 19.196 115.651 18.7564 115.044 18.1154C114.438 17.4744 114.135 16.5769 114.135 15.4231C114.135 14.4707 114.42 13.6282 114.989 12.8956C115.559 12.1447 116.385 11.5586 117.469 11.1374C118.571 10.6978 119.884 10.478 121.408 10.478C122.492 10.478 123.567 10.5879 124.632 10.8077C125.697 11.0275 126.588 11.3388 127.304 11.7418L125.734 15.2857C124.411 14.5531 122.979 14.1868 121.436 14.1868C120.61 14.1868 120.003 14.2875 119.618 14.489C119.232 14.6722 119.039 14.9194 119.039 15.2308C119.039 15.5788 119.25 15.826 119.673 15.9725C120.095 16.1007 120.793 16.2381 121.767 16.3846C122.997 16.5678 124.017 16.7784 124.825 17.0165C125.633 17.2546 126.331 17.6941 126.918 18.3352C127.525 18.9579 127.828 19.837 127.828 20.9725C127.828 21.9066 127.543 22.7491 126.974 23.5C126.404 24.2326 125.559 24.8095 124.439 25.2308C123.337 25.652 122.005 25.8626 120.444 25.8626Z" fill="#0B0B0B"></path>
<path className="letter-main" d="M140.387 25.011C139.946 25.2857 139.404 25.4963 138.761 25.6429C138.137 25.7894 137.467 25.8626 136.75 25.8626C134.803 25.8626 133.307 25.3864 132.26 24.4341C131.213 23.4817 130.689 22.0623 130.689 20.1758V15.1758H128.513V11.2747H130.689V7.4011H135.924V11.2747H139.34V15.1758H135.924V20.1209C135.924 20.652 136.062 21.0733 136.337 21.3846C136.631 21.6777 137.017 21.8242 137.494 21.8242C138.1 21.8242 138.633 21.6685 139.092 21.3571L140.387 25.011Z" fill="#0B0B0B"></path>
<path className="letter-main" d="M160.286 26C158.265 26 156.447 25.5788 154.831 24.7363C153.233 23.8755 151.975 22.685 151.056 21.1648C150.138 19.6447 149.679 17.9231 149.679 16C149.679 14.0769 150.138 12.3553 151.056 10.8352C151.975 9.31502 153.233 8.1337 154.831 7.29121C156.447 6.4304 158.265 6 160.286 6C162.049 6 163.638 6.31136 165.052 6.93407C166.466 7.55678 167.641 8.45421 168.578 9.62637L165.107 12.7582C163.858 11.2564 162.343 10.5055 160.561 10.5055C159.514 10.5055 158.578 10.7344 157.751 11.1923C156.943 11.6502 156.309 12.3004 155.85 13.1429C155.409 13.967 155.189 14.9194 155.189 16C155.189 17.0806 155.409 18.0421 155.85 18.8846C156.309 19.7088 156.943 20.3498 157.751 20.8077C158.578 21.2656 159.514 21.4945 160.561 21.4945C162.343 21.4945 163.858 20.7436 165.107 19.2418L168.578 22.3736C167.641 23.5458 166.466 24.4432 165.052 25.0659C163.638 25.6886 162.049 26 160.286 26Z" fill="#0B0B0B"></path>
<path className="letter-main" d="M177.798 25.8626C176.201 25.8626 174.759 25.533 173.473 24.8736C172.187 24.2143 171.177 23.2985 170.443 22.1264C169.726 20.9542 169.368 19.6264 169.368 18.1429C169.368 16.6777 169.726 15.359 170.443 14.1868C171.177 13.0147 172.178 12.1081 173.446 11.467C174.731 10.8077 176.182 10.478 177.798 10.478C179.415 10.478 180.866 10.8077 182.151 11.467C183.437 12.1081 184.438 13.0147 185.154 14.1868C185.871 15.3407 186.229 16.6593 186.229 18.1429C186.229 19.6264 185.871 20.9542 185.154 22.1264C184.438 23.2985 183.437 24.2143 182.151 24.8736C180.866 25.533 179.415 25.8626 177.798 25.8626ZM177.798 21.7143C178.717 21.7143 179.47 21.4029 180.058 20.7802C180.645 20.1392 180.939 19.2601 180.939 18.1429C180.939 17.044 180.645 16.1831 180.058 15.5604C179.47 14.9377 178.717 14.6264 177.798 14.6264C176.88 14.6264 176.127 14.9377 175.539 15.5604C174.952 16.1831 174.658 17.044 174.658 18.1429C174.658 19.2601 174.952 20.1392 175.539 20.7802C176.127 21.4029 176.88 21.7143 177.798 21.7143Z" fill="#0B0B0B"></path>
<path className="letter-main" d="M194.695 10.478C197.174 10.478 199.066 11.0458 200.37 12.1813C201.674 13.2985 202.326 15.0201 202.326 17.3462V25.6154H197.45V23.6923C196.697 25.1392 195.227 25.8626 193.042 25.8626C191.885 25.8626 190.884 25.6612 190.039 25.2582C189.194 24.8553 188.551 24.315 188.11 23.6374C187.688 22.9414 187.477 22.1538 187.477 21.2747C187.477 19.8645 188.018 18.7747 189.102 18.0055C190.186 17.2179 191.857 16.8242 194.116 16.8242H197.092C197 15.2491 195.944 14.4615 193.923 14.4615C193.207 14.4615 192.482 14.5806 191.747 14.8187C191.012 15.0385 190.388 15.3498 189.873 15.7527L188.11 12.2088C188.937 11.6593 189.938 11.2381 191.113 10.9451C192.307 10.6337 193.501 10.478 194.695 10.478ZM194.502 22.5659C195.108 22.5659 195.641 22.4194 196.1 22.1264C196.559 21.8333 196.89 21.4029 197.092 20.8352V19.544H194.832C193.308 19.544 192.546 20.0476 192.546 21.0549C192.546 21.5128 192.72 21.8791 193.069 22.1538C193.418 22.4286 193.896 22.5659 194.502 22.5659Z" fill="#0B0B0B"></path>
<path className="letter-main" d="M211.057 25.8626C209.808 25.8626 208.578 25.7253 207.366 25.4505C206.154 25.1575 205.18 24.7821 204.445 24.3242L206.016 20.7527C206.695 21.174 207.503 21.5128 208.44 21.7692C209.377 22.0073 210.304 22.1264 211.223 22.1264C212.068 22.1264 212.664 22.044 213.013 21.8791C213.381 21.696 213.564 21.4396 213.564 21.1099C213.564 20.7802 213.344 20.5513 212.903 20.4231C212.481 20.2766 211.801 20.1392 210.865 20.011C209.671 19.8645 208.651 19.6722 207.806 19.4341C206.98 19.196 206.264 18.7564 205.658 18.1154C205.051 17.4744 204.748 16.5769 204.748 15.4231C204.748 14.4707 205.033 13.6282 205.603 12.8956C206.172 12.1447 206.998 11.5586 208.082 11.1374C209.184 10.6978 210.497 10.478 212.022 10.478C213.105 10.478 214.18 10.5879 215.245 10.8077C216.31 11.0275 217.201 11.3388 217.917 11.7418L216.347 15.2857C215.025 14.5531 213.592 14.1868 212.049 14.1868C211.223 14.1868 210.617 14.2875 210.231 14.489C209.845 14.6722 209.652 14.9194 209.652 15.2308C209.652 15.5788 209.864 15.826 210.286 15.9725C210.708 16.1007 211.406 16.2381 212.38 16.3846C213.61 16.5678 214.63 16.7784 215.438 17.0165C216.246 17.2546 216.944 17.6941 217.532 18.3352C218.138 18.9579 218.441 19.837 218.441 20.9725C218.441 21.9066 218.156 22.7491 217.587 23.5C217.017 24.2326 216.172 24.8095 215.052 25.2308C213.95 25.652 212.619 25.8626 211.057 25.8626Z" fill="#0B0B0B"></path>
<path className="letter-main" d="M231 25.011C230.559 25.2857 230.017 25.4963 229.375 25.6429C228.75 25.7894 228.08 25.8626 227.363 25.8626C225.417 25.8626 223.92 25.3864 222.873 24.4341C221.826 23.4817 221.302 22.0623 221.302 20.1758V15.1758H219.126V11.2747H221.302V7.4011H226.537V11.2747H229.953V15.1758H226.537V20.1209C226.537 20.652 226.675 21.0733 226.95 21.3846C227.244 21.6777 227.63 21.8242 228.107 21.8242C228.713 21.8242 229.246 21.6685 229.705 21.3571L231 25.011Z" fill="#0B0B0B"></path>
<path className="letter-main" d="M75.19 33.11V43.1132H72.875V39.0119H68.3307V43.1132H66.0156V33.11H68.3307V37.0541H72.875V33.11H75.19Z" fill="#6F6F6F"></path>
<path className="letter-main" d="M85.0306 35.425L81.558 43.5848C81.2055 44.4708 80.7673 45.0948 80.2433 45.4568C79.7289 45.8188 79.1049 45.9998 78.3713 45.9998C77.9712 45.9998 77.5758 45.9379 77.1852 45.8141C76.7946 45.6902 76.4754 45.5187 76.2277 45.2996L77.0423 43.7134C77.2138 43.8658 77.4091 43.9849 77.6282 44.0706C77.8568 44.1564 78.0807 44.1993 78.2998 44.1993C78.6047 44.1993 78.8524 44.123 79.0429 43.9706C79.2335 43.8277 79.405 43.5848 79.5574 43.2418L79.586 43.1703L76.2563 35.425H78.5571L80.7149 40.641L82.887 35.425H85.0306Z" fill="#6F6F6F"></path>
<path className="letter-main" d="M93.562 32.5098V43.1132H91.4328V42.2272C90.8802 42.8941 90.0799 43.2275 89.032 43.2275C88.3079 43.2275 87.6506 43.0656 87.0599 42.7416C86.4788 42.4177 86.0215 41.9557 85.688 41.3555C85.3546 40.7553 85.1879 40.0598 85.1879 39.2691C85.1879 38.4784 85.3546 37.7829 85.688 37.1827C86.0215 36.5825 86.4788 36.1205 87.0599 35.7965C87.6506 35.4726 88.3079 35.3107 89.032 35.3107C90.0133 35.3107 90.7802 35.6203 91.3327 36.2395V32.5098H93.562ZM89.4178 41.3983C89.9799 41.3983 90.4467 41.2078 90.8183 40.8267C91.1898 40.4361 91.3756 39.9169 91.3756 39.2691C91.3756 38.6213 91.1898 38.1068 90.8183 37.7257C90.4467 37.3351 89.9799 37.1398 89.4178 37.1398C88.8462 37.1398 88.3746 37.3351 88.0031 37.7257C87.6315 38.1068 87.4458 38.6213 87.4458 39.2691C87.4458 39.9169 87.6315 40.4361 88.0031 40.8267C88.3746 41.2078 88.8462 41.3983 89.4178 41.3983Z" fill="#6F6F6F"></path>
<path className="letter-main" d="M97.7546 36.4396C98.0213 36.0681 98.3786 35.787 98.8263 35.5965C99.2836 35.4059 99.8076 35.3107 100.398 35.3107V37.3685C100.151 37.3494 99.9838 37.3399 99.8981 37.3399C99.2598 37.3399 98.7596 37.5209 98.3976 37.8829C98.0356 38.2354 97.8546 38.7689 97.8546 39.4834V43.1132H95.6253V35.425H97.7546V36.4396Z" fill="#6F6F6F"></path>
<path className="letter-main" d="M105.312 43.2275C104.503 43.2275 103.774 43.0608 103.126 42.7273C102.488 42.3844 101.987 41.9128 101.625 41.3126C101.263 40.7124 101.082 40.0312 101.082 39.2691C101.082 38.5069 101.263 37.8258 101.625 37.2256C101.987 36.6254 102.488 36.1586 103.126 35.8251C103.774 35.4822 104.503 35.3107 105.312 35.3107C106.122 35.3107 106.846 35.4822 107.484 35.8251C108.123 36.1586 108.623 36.6254 108.985 37.2256C109.347 37.8258 109.528 38.5069 109.528 39.2691C109.528 40.0312 109.347 40.7124 108.985 41.3126C108.623 41.9128 108.123 42.3844 107.484 42.7273C106.846 43.0608 106.122 43.2275 105.312 43.2275ZM105.312 41.3983C105.884 41.3983 106.351 41.2078 106.713 40.8267C107.084 40.4361 107.27 39.9169 107.27 39.2691C107.27 38.6213 107.084 38.1068 106.713 37.7257C106.351 37.3351 105.884 37.1398 105.312 37.1398C104.741 37.1398 104.269 37.3351 103.898 37.7257C103.526 38.1068 103.34 38.6213 103.34 39.2691C103.34 39.9169 103.526 40.4361 103.898 40.8267C104.269 41.2078 104.741 41.3983 105.312 41.3983Z" fill="#6F6F6F"></path>
<path className="letter-main" d="M115.691 35.3107C116.644 35.3107 117.411 35.5965 117.992 36.1681C118.582 36.7397 118.878 37.5876 118.878 38.7118V43.1132H116.648V39.0547C116.648 38.445 116.515 37.9925 116.248 37.6972C115.982 37.3923 115.596 37.2399 115.091 37.2399C114.529 37.2399 114.081 37.4161 113.748 37.7686C113.414 38.1116 113.247 38.626 113.247 39.312V43.1132H111.018V35.425H113.147V36.3253C113.443 36.0014 113.809 35.7537 114.248 35.5822C114.686 35.4012 115.167 35.3107 115.691 35.3107Z" fill="#6F6F6F"></path>
<path className="letter-main" d="M120.899 35.425H123.128V43.1132H120.899V35.425ZM122.013 34.3532C121.604 34.3532 121.27 34.2341 121.013 33.996C120.756 33.7578 120.627 33.4625 120.627 33.11C120.627 32.7575 120.756 32.4621 121.013 32.224C121.27 31.9858 121.604 31.8667 122.013 31.8667C122.423 31.8667 122.756 31.981 123.013 32.2097C123.271 32.4383 123.399 32.7241 123.399 33.0671C123.399 33.4386 123.271 33.7483 123.013 33.996C122.756 34.2341 122.423 34.3532 122.013 34.3532Z" fill="#6F6F6F"></path>
<path className="letter-main" d="M128.912 43.2275C128.093 43.2275 127.355 43.0608 126.697 42.7273C126.049 42.3844 125.54 41.9128 125.168 41.3126C124.806 40.7124 124.625 40.0312 124.625 39.2691C124.625 38.5069 124.806 37.8258 125.168 37.2256C125.54 36.6254 126.049 36.1586 126.697 35.8251C127.355 35.4822 128.093 35.3107 128.912 35.3107C129.722 35.3107 130.427 35.4822 131.027 35.8251C131.637 36.1586 132.08 36.6397 132.356 37.2684L130.627 38.1973C130.227 37.4923 129.651 37.1398 128.898 37.1398C128.317 37.1398 127.836 37.3304 127.455 37.7114C127.074 38.0925 126.883 38.6117 126.883 39.2691C126.883 39.9264 127.074 40.4457 127.455 40.8267C127.836 41.2078 128.317 41.3983 128.898 41.3983C129.66 41.3983 130.237 41.0459 130.627 40.3409L132.356 41.284C132.08 41.8937 131.637 42.3701 131.027 42.7131C130.427 43.056 129.722 43.2275 128.912 43.2275Z" fill="#6F6F6F"></path>
<path className="letter-main" d="M136.226 43.2275C135.588 43.2275 134.964 43.1513 134.354 42.9989C133.745 42.8369 133.259 42.6368 132.897 42.3987L133.64 40.7982C133.983 41.0173 134.397 41.1983 134.883 41.3412C135.369 41.4746 135.845 41.5413 136.312 41.5413C137.255 41.5413 137.727 41.3078 137.727 40.841C137.727 40.6219 137.598 40.4647 137.341 40.3694C137.084 40.2742 136.688 40.1932 136.155 40.1265C135.526 40.0312 135.007 39.9217 134.597 39.7978C134.188 39.674 133.83 39.4549 133.525 39.1405C133.23 38.8261 133.082 38.3783 133.082 37.7972C133.082 37.3113 133.221 36.8826 133.497 36.5111C133.783 36.13 134.192 35.8346 134.726 35.6251C135.269 35.4155 135.907 35.3107 136.641 35.3107C137.184 35.3107 137.722 35.3726 138.256 35.4964C138.799 35.6108 139.246 35.7727 139.599 35.9823L138.856 37.5685C138.179 37.1875 137.441 36.9969 136.641 36.9969C136.164 36.9969 135.807 37.0636 135.569 37.197C135.331 37.3304 135.212 37.5019 135.212 37.7114C135.212 37.9496 135.34 38.1163 135.598 38.2116C135.855 38.3069 136.264 38.3974 136.827 38.4831C137.455 38.5879 137.97 38.7022 138.37 38.8261C138.77 38.9404 139.118 39.1548 139.413 39.4692C139.708 39.7835 139.856 40.2218 139.856 40.7839C139.856 41.2602 139.713 41.6842 139.427 42.0557C139.142 42.4273 138.722 42.7178 138.17 42.9274C137.627 43.1275 136.979 43.2275 136.226 43.2275Z" fill="#6F6F6F"></path>
<path className="letter-main" d="M153.124 43.2847L151.924 42.17C150.971 42.9131 149.856 43.2847 148.58 43.2847C147.856 43.2847 147.203 43.1704 146.622 42.9417C146.041 42.7035 145.583 42.3749 145.25 41.9557C144.926 41.5365 144.764 41.0649 144.764 40.5409C144.764 39.9217 144.936 39.3786 145.279 38.9118C145.631 38.4355 146.193 37.983 146.965 37.5542C146.603 37.1827 146.341 36.8302 146.179 36.4968C146.017 36.1538 145.936 35.8013 145.936 35.4393C145.936 34.9534 146.065 34.5247 146.322 34.1532C146.579 33.7816 146.941 33.4958 147.408 33.2957C147.884 33.0861 148.432 32.9813 149.051 32.9813C149.918 32.9813 150.614 33.1909 151.138 33.6101C151.662 34.0198 151.924 34.5771 151.924 35.2821C151.924 35.7965 151.766 36.2538 151.452 36.654C151.147 37.0541 150.652 37.4399 149.966 37.8115L151.795 39.512C152.014 39.0547 152.181 38.5355 152.295 37.9544L154.081 38.5117C153.891 39.3977 153.586 40.1551 153.167 40.7839L154.339 41.8699L153.124 43.2847ZM149.051 34.4961C148.718 34.4961 148.451 34.5819 148.251 34.7533C148.06 34.9248 147.965 35.1439 147.965 35.4107C147.965 35.6108 148.018 35.8013 148.122 35.9823C148.227 36.1538 148.432 36.3872 148.737 36.6825C149.223 36.4253 149.561 36.1967 149.751 35.9966C149.942 35.787 150.037 35.5631 150.037 35.325C150.037 35.0773 149.952 34.8772 149.78 34.7248C149.609 34.5723 149.366 34.4961 149.051 34.4961ZM148.737 41.5413C149.432 41.5413 150.056 41.346 150.609 40.9553L148.194 38.6975C147.746 38.9547 147.427 39.2072 147.236 39.4549C147.046 39.7026 146.951 39.9836 146.951 40.298C146.951 40.6695 147.113 40.9696 147.436 41.1983C147.76 41.4269 148.194 41.5413 148.737 41.5413Z" fill="#6F6F6F"></path>
<path className="letter-main" d="M164.21 33.11C165.096 33.11 165.863 33.2576 166.51 33.553C167.168 33.8483 167.673 34.2675 168.025 34.8105C168.378 35.3535 168.554 35.9966 168.554 36.7397C168.554 37.4733 168.378 38.1163 168.025 38.6689C167.673 39.2119 167.168 39.6311 166.51 39.9264C165.863 40.2123 165.096 40.3552 164.21 40.3552H162.195V43.1132H159.88V33.11H164.21ZM164.081 38.4688C164.776 38.4688 165.305 38.3212 165.667 38.0258C166.029 37.721 166.21 37.2923 166.21 36.7397C166.21 36.1776 166.029 35.7489 165.667 35.4536C165.305 35.1487 164.776 34.9963 164.081 34.9963H162.195V38.4688H164.081Z" fill="#6F6F6F"></path>
<path className="letter-main" d="M170.189 32.5098H172.418V43.1132H170.189V32.5098Z" fill="#6F6F6F"></path>
<path className="letter-main" d="M182.233 35.425V43.1132H180.118V42.1986C179.822 42.532 179.47 42.7893 179.06 42.9703C178.65 43.1418 178.207 43.2275 177.731 43.2275C176.721 43.2275 175.921 42.9369 175.33 42.3558C174.74 41.7747 174.444 40.9125 174.444 39.7693V35.425H176.674V39.4406C176.674 40.6791 177.193 41.2983 178.231 41.2983C178.765 41.2983 179.194 41.1268 179.517 40.7839C179.841 40.4314 180.003 39.9122 180.003 39.2262V35.425H182.233Z" fill="#6F6F6F"></path>
<path className="letter-main" d="M194.144 35.3107C195.106 35.3107 195.868 35.5965 196.43 36.1681C197.002 36.7302 197.288 37.5781 197.288 38.7118V43.1132H195.059V39.0547C195.059 38.445 194.93 37.9925 194.673 37.6972C194.425 37.3923 194.068 37.2399 193.601 37.2399C193.077 37.2399 192.662 37.4113 192.358 37.7543C192.053 38.0878 191.9 38.5879 191.9 39.2548V43.1132H189.671V39.0547C189.671 37.8448 189.185 37.2399 188.213 37.2399C187.699 37.2399 187.289 37.4113 186.984 37.7543C186.68 38.0878 186.527 38.5879 186.527 39.2548V43.1132H184.298V35.425H186.427V36.311C186.713 35.9871 187.061 35.7394 187.47 35.5679C187.89 35.3964 188.347 35.3107 188.842 35.3107C189.385 35.3107 189.876 35.4202 190.314 35.6393C190.752 35.8489 191.105 36.1586 191.372 36.5682C191.686 36.1681 192.081 35.8585 192.558 35.6393C193.044 35.4202 193.572 35.3107 194.144 35.3107Z" fill="#6F6F6F"></path>
<path className="letter-main" d="M203.83 35.3107C204.545 35.3107 205.193 35.4774 205.774 35.8108C206.364 36.1347 206.827 36.5968 207.16 37.197C207.493 37.7877 207.66 38.4784 207.66 39.2691C207.66 40.0598 207.493 40.7553 207.16 41.3555C206.827 41.9461 206.364 42.4082 205.774 42.7416C205.193 43.0656 204.545 43.2275 203.83 43.2275C202.773 43.2275 201.968 42.8941 201.415 42.2272V43.1132H199.286V32.5098H201.515V36.2395C202.077 35.6203 202.849 35.3107 203.83 35.3107ZM203.444 41.3983C204.016 41.3983 204.483 41.2078 204.845 40.8267C205.216 40.4361 205.402 39.9169 205.402 39.2691C205.402 38.6213 205.216 38.1068 204.845 37.7257C204.483 37.3351 204.016 37.1398 203.444 37.1398C202.873 37.1398 202.401 37.3351 202.03 37.7257C201.668 38.1068 201.487 38.6213 201.487 39.2691C201.487 39.9169 201.668 40.4361 202.03 40.8267C202.401 41.2078 202.873 41.3983 203.444 41.3983Z" fill="#6F6F6F"></path>
<path className="letter-main" d="M209.152 35.425H211.382V43.1132H209.152V35.425ZM210.267 34.3532C209.857 34.3532 209.524 34.2341 209.267 33.996C209.01 33.7578 208.881 33.4625 208.881 33.11C208.881 32.7575 209.01 32.4621 209.267 32.224C209.524 31.9858 209.857 31.8667 210.267 31.8667C210.677 31.8667 211.01 31.981 211.267 32.2097C211.525 32.4383 211.653 32.7241 211.653 33.0671C211.653 33.4386 211.525 33.7483 211.267 33.996C211.01 34.2341 210.677 34.3532 210.267 34.3532Z" fill="#6F6F6F"></path>
<path className="letter-main" d="M218.124 35.3107C219.076 35.3107 219.843 35.5965 220.424 36.1681C221.015 36.7397 221.31 37.5876 221.31 38.7118V43.1132H219.081V39.0547C219.081 38.445 218.948 37.9925 218.681 37.6972C218.414 37.3923 218.028 37.2399 217.523 37.2399C216.961 37.2399 216.514 37.4161 216.18 37.7686C215.847 38.1116 215.68 38.626 215.68 39.312V43.1132H213.451V35.425H215.58V36.3253C215.875 36.0014 216.242 35.7537 216.68 35.5822C217.119 35.4012 217.6 35.3107 218.124 35.3107Z" fill="#6F6F6F"></path>
<path className="letter-main" d="M231.346 35.425V41.8271C231.346 43.237 230.979 44.285 230.246 44.9709C229.512 45.6569 228.44 45.9998 227.03 45.9998C226.287 45.9998 225.582 45.9093 224.915 45.7283C224.248 45.5473 223.696 45.2853 223.258 44.9423L224.144 43.3418C224.468 43.6086 224.877 43.8182 225.373 43.9706C225.868 44.1326 226.363 44.2135 226.859 44.2135C227.631 44.2135 228.197 44.0373 228.559 43.6848C228.931 43.3418 229.117 42.8179 229.117 42.1129V41.7842C228.536 42.4225 227.726 42.7416 226.687 42.7416C225.982 42.7416 225.335 42.5892 224.744 42.2843C224.163 41.97 223.701 41.5317 223.358 40.9696C223.015 40.4076 222.843 39.7597 222.843 39.0262C222.843 38.2926 223.015 37.6448 223.358 37.0827C223.701 36.5206 224.163 36.0871 224.744 35.7822C225.335 35.4679 225.982 35.3107 226.687 35.3107C227.802 35.3107 228.65 35.6775 229.231 36.411V35.425H231.346ZM227.13 40.9125C227.721 40.9125 228.202 40.741 228.574 40.398C228.955 40.0455 229.145 39.5882 229.145 39.0262C229.145 38.4641 228.955 38.0115 228.574 37.6686C228.202 37.3161 227.721 37.1398 227.13 37.1398C226.54 37.1398 226.054 37.3161 225.673 37.6686C225.292 38.0115 225.101 38.4641 225.101 39.0262C225.101 39.5882 225.292 40.0455 225.673 40.398C226.054 40.741 226.54 40.9125 227.13 40.9125Z" fill="#6F6F6F"></path>
<rect width="51" height="51" rx="18" fill="#2563EB"></rect>
<g clip-path="url(#clip0_1020_2)">
<path className="letter-main" d="M29.496 26.9815L29.4803 26.9972C28.209 26.5631 27.0547 25.8424 26.1067 24.8908C25.1586 23.9391 24.4422 22.7821 24.0129 21.5093L11.0952 34.4251C10.386 35.1553 9.99266 36.1353 10.0001 37.1532C10.0075 38.171 10.4152 39.1451 11.135 39.8649C11.8547 40.5846 12.8288 40.9923 13.8467 40.9997C14.8646 41.0072 15.8445 40.6138 16.5747 39.9046L29.496 26.9827V26.9815ZM13.8356 38.6174C13.5482 38.6174 13.2672 38.5322 13.0282 38.3725C12.7893 38.2128 12.603 37.9859 12.493 37.7204C12.3831 37.4548 12.3543 37.1627 12.4104 36.8808C12.4664 36.5989 12.6048 36.34 12.808 36.1368C13.0113 35.9335 13.2702 35.7951 13.5521 35.7391C13.8339 35.683 14.1261 35.7118 14.3916 35.8218C14.6572 35.9317 14.8841 36.118 15.0438 36.357C15.2035 36.5959 15.2887 36.8769 15.2887 37.1643C15.2887 37.5497 15.1356 37.9193 14.8631 38.1918C14.5906 38.4643 14.2209 38.6174 13.8356 38.6174Z" fill="#C6E1F9"></path>
<path className="letter-main" d="M38.4383 24.9065C36.0165 27.3284 32.5362 27.9944 29.4943 26.9815L29.4786 26.9972C28.2043 26.5621 27.0474 25.8389 26.098 24.8839C25.1486 23.929 24.4323 22.768 24.0046 21.4911L24.0124 21.4832C23.011 18.4456 23.6734 14.9617 26.0789 12.5586C27.145 11.493 28.4686 10.7211 29.921 10.3179C31.3735 9.9148 32.9055 9.8941 34.3684 10.2578C34.4943 10.2879 34.61 10.351 34.7033 10.4407C34.7967 10.5304 34.8644 10.6435 34.8994 10.7681C34.9344 10.8928 34.9354 11.0245 34.9024 11.1497C34.8694 11.275 34.8036 11.3891 34.7117 11.4803L30.1991 15.9898L30.8851 20.107L35.0059 20.7942L39.5185 16.2822C39.6091 16.1906 39.7225 16.1248 39.8469 16.0914C39.9714 16.0581 40.1025 16.0584 40.2268 16.0925C40.3511 16.1265 40.4641 16.1929 40.5542 16.285C40.6443 16.3771 40.7084 16.4915 40.7397 16.6165C41.1058 18.0793 41.0862 19.6121 40.6828 21.0651C40.2794 22.5182 39.5061 23.8417 38.4383 24.9065Z" fill="white"></path>
</g>
<defs>
<clipPath id="clip0_1020_2">
<rect width="31" height="31" fill="white" transform="translate(10 10)"></rect>
</clipPath>
</defs>
</svg>

);

export default Logo;

